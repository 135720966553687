import {
    JOB_FACET_COMPONENT,
    JOB_PHARMA_FACET_COMPONENT
} from '../dynamic/lazy-components.token';
import { DragulaModule } from "ng2-dragula";
import { FacetSharedModule } from '@common/facet/facet-shared.module';
import { MessagesSharedModule } from '../messages/messages-shared.module';
import { EnumerationsSharedModule } from '../enumerations/enumerations-shared.module';
import { EntityChangesModule } from '../entity-changes/entity-changes.module';
import { ClinicalSharedModule } from '../clinical/clinical-shared.module';
import { SamplesSharedModule } from '../samples/samples-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';

import { JobsSharedModule } from './jobs-shared.module';

import {
    JobAnimalTableComponent,
    JobCohortTableComponent,
    JobSampleTableComponent
} from './tables';
import { JobDetailComponent } from './job-detail.component';
import { JobFacetComponent } from './job-facet.component';

import { JobPharmaDetailComponent } from './pharma/job-pharma-detail.component';
import { JobPharmaFacetComponent } from './pharma/job-pharma-facet.component';
import {
    JobPharmaAnimalsCohortsTableComponent,
    JobPharmaAnimalsPlaceholderTableComponent,
    JobPharmaAnimalsIndividualTableComponent,
    JobPharmaSamplesGroupsTableComponent,
    JobPharmaSamplesIndividualTableComponent,
    JobPharmaTasksInputsTableComponent,
    JobPharmaTasksListTableComponent,
    JobPharmaTasksScheduleTableComponent
} from './pharma/tables';
import { JobPharmaReportTableComponent } from './reports/job-pharma-report-table.component';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { AuditSharedModule } from '../audit/audit-shared.module';
import { CharacteristicsSharedModule } from '../characteristics/characteristics-shared.module';
import { DataTypeModule } from '../data-type/data-type.module';
import { InstitutionsSharedModule } from '../institution/institutions-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { LocationsSharedModule } from '../locations/locations-shared.module';
import { ProtocolsSharedModule } from '../protocol/protocols-shared.module';
import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { SearchSharedModule } from '../search/search-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';
import { WorkflowsSharedModule } from '../workflow/workflows-shared.module';
import { TaskTableModule } from '../tasks/task-table.module';
import { JobTestArticleTableComponent } from './job-test-article-table.component';
import { JobGroupTableComponent, JobGroupValidator } from './components/job-group-table';
import { JobLineTableComponent } from './job-line-table.component';
import { LineDetailsTableComponent } from './line-details-table.component';
import { ReportingSharedModule } from '../reporting/reporting-shared.module';
import { JobInstitutionTableComponent } from './job-institution-table.component';
import { DotmaticsModule } from '../dotmatics/dotmatics.module';
import { ProtocolsPipe } from './pipes/protocols-filter.pipe';
import { JobPharmaAnimalsIndividualPlaceholderTableComponent } from './pharma/tables/job-pharma-animals-individual-placeholder-table.component';
import { JobFilterComponent } from './job-filter.component';
import { OrdersSharedModule } from '../orders/orders-shared.module';
import { JobPharmaTasksOutlineTableComponent } from './pharma/tables';
import { JobLocationTableComponent } from './job-location-table.component';
import { JobGroupInputTrimDirective } from './components/job-group-table/job-group-input-trim.directive';
import { JobGroupInputNumberDirective } from './components/job-group-table/job-group-input-number.directive';
import { JobPharmaReportService } from './reports/job-pharma-report.service';
import { JobPharmaCoreService, JobPharmaDataAccessService, JobPharmaDetailService, JobPharmaTableService } from './pharma/services';
import { JobPharmaSampleGroupsTableService } from './pharma/tables/samples-group-table/job-pharma-samples-groups-table.service';
import { HasAssociatedSamplesPipe } from './pipes/has-associated-samples.pipe';
import { JobPharmaAnimalsIndividualTableService } from './pharma/tables/animals-individual-table/job-pharma-animals-individual-table.service';

@NgModule({
    imports: [
        CommonModule,
        DragulaModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        NgxPaginationModule,
        AnimalsSharedModule,
        AuditSharedModule,
        CharacteristicsSharedModule,
        ClinicalSharedModule,
        DataTypeModule,
        EntityChangesModule,
        EnumerationsSharedModule,
        InstitutionsSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        LocationsSharedModule,
        MessagesSharedModule,
        ProtocolsSharedModule,
        ResourcesSharedModule,
        SamplesSharedModule,
        SearchSharedModule,
        StudiesSharedModule,
        TaskTableModule,
        TasksSharedModule,
        UsersSharedModule,
        WorkflowsSharedModule,
        WorkspacesSharedModule,
        VocabulariesSharedModule,
        ReportingSharedModule,
        DotmaticsModule,
        OrdersSharedModule
    ],
    declarations: [
        JobAnimalTableComponent,
        JobCohortTableComponent,
        JobSampleTableComponent,
        JobDetailComponent,
        JobFacetComponent,
        JobPharmaDetailComponent,
        JobPharmaFacetComponent,
        JobPharmaAnimalsCohortsTableComponent,
        JobPharmaAnimalsPlaceholderTableComponent,
        JobPharmaAnimalsIndividualPlaceholderTableComponent,
        JobPharmaAnimalsIndividualTableComponent,
        JobPharmaSamplesGroupsTableComponent,
        JobPharmaSamplesIndividualTableComponent,
        JobPharmaTasksInputsTableComponent,
        JobPharmaTasksListTableComponent,
        JobPharmaTasksOutlineTableComponent,
        JobPharmaTasksScheduleTableComponent,
        JobTestArticleTableComponent,
        JobGroupTableComponent,
        JobGroupValidator,
        JobGroupInputTrimDirective,
        JobGroupInputNumberDirective,
        JobLineTableComponent,
        LineDetailsTableComponent,
        JobInstitutionTableComponent,
        JobLocationTableComponent,
        ProtocolsPipe,
        JobFilterComponent,
        JobPharmaReportTableComponent,
        HasAssociatedSamplesPipe
    ],
    providers: [
        { provide: JOB_FACET_COMPONENT, useValue: JobFacetComponent },
        {
            provide: JOB_PHARMA_FACET_COMPONENT,
            useValue: JobPharmaFacetComponent
        },
        JobPharmaDetailService,
        JobPharmaReportService,
        JobPharmaTableService,
        JobPharmaAnimalsIndividualTableService,
        JobPharmaDataAccessService,
        JobPharmaCoreService,
        JobPharmaSampleGroupsTableService,
    ],
    exports: [
        JobFilterComponent
    ]
})
export class JobsModule {}
